<template>
  <div>

    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          Filtros
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="ID"
              label-for="filterID"
            >
              <b-form-input
                id="filterID"
                v-model="dataFilter.id"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">Ação</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              Pesquisar
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="carteiras"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
        striped
        no-local-sorting="true"
        @sort-changed="onSortChanged"
      >

        <template #cell(acao)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click.stop="relatorioCarteira(data.item.id)"
            >
              <feather-icon icon="LayoutIcon" />
              <span class="align-middle ml-50">Relatório</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click.stop="confirmarDeletar(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ paginationMeta.from }} a {{ paginationMeta.to }} de {{ paginationMeta.of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>

import {
  BCard, BTable, BDropdown, BDropdownItem, BPagination, BRow, BCol, BFormGroup, BFormInput, BCardHeader, BCardBody, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import carteiraStoreModule from './carteiraStoreModule'

const CARTEIRA_STORE_MODULE_NAME = 'carteira'

export default {

  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      tableColumns: [
        { key: 'id', label: 'ID' },
        { key: 'dataCriacao', label: 'Criado em', sortable: true },
        { key: 'dataInicioBT', label: 'Início BT' },
        { key: 'dataFimBT', label: 'Fim BT', sortable: true },
        { key: 'acao', label: 'Ações' },
      ],
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      isSortDirDesc: true,
      sortBy: 'dataCriacao',
      carteiras: [],
      dataFilter: [],
    }
  },

  computed: {
    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },

    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
    currentPage() {
      this.getAll()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(CARTEIRA_STORE_MODULE_NAME)) store.registerModule(CARTEIRA_STORE_MODULE_NAME, carteiraStoreModule)
    this.getAll()
  },

  beforeDestroy() {
    if (store.hasModule(CARTEIRA_STORE_MODULE_NAME)) store.unregisterModule(CARTEIRA_STORE_MODULE_NAME)
  },

  methods: {

    buscarFiltro() {
      this.page = 1
      this.getAll()
    },

    onSortChanged(sort) {
      this.sortBy = sort.sortBy
      this.isSortDirDesc = sort.sortDesc
      this.buscarFiltro()
    },

    getAll() {
      store
        .dispatch('carteira/getAll', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          id: this.dataFilter.id,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.carteiras = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    relatorioCarteira(id) {
      window.open(`/backtester/carteira/${id}/relatorio`, '_blank')
    },

    // Deletar backtest
    confirmarDeletar(id) {
      this.$swal({
        title: 'Tem certeza que deseja excluir?',
        text: 'Sua carteira será apagada permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode apagar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deletar(id)
        }
      })
    },

    deletar(id) {
      this.$store.dispatch('carteira/excluir', { idCarteira: id })
        .then(() => {
          this.getAll()
          this.$swal({
            icon: 'success',
            title: 'Apagando Carteira!',
            text: 'Sua Carteira está sendo apagada.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

  },

}
</script>
